import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios'
import router from "./router";
//

import request from "./request";
import Router from "vue-router";
import {getToken, setToken} from './auth'
import {login} from './api'


Vue.config.productionTip = false
Vue.use(ElementUI);


Vue.prototype.$axios = axios
window.$request = request;

router.beforeEach((to,from,next)=>{
  if(getToken()){
    next()
  }else{
    if(to.path === '/'){
      next()
    }else{
      if(to.query && to.query.type === 'sso'){
        let temp = {loginType:'sso',username:to.query.user,password:''}
        console.log(temp,333333,to)
        login(temp).then(res=>{
          if(res.code === '0000'){
            setToken(res.data.token);
            next({
              path:to.path,
              query:to.query
            })
          }else{
            next('/')
          }
        }).catch(()=>{
          next('/')

        })
      }else{
        next('/')
      }
    }
  }
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
